import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'App',
    component: Layout,
    redirect: '/',
    children: [{
      path:'/',
      name: 'Home',
      component: () => import('@/views/home.vue'),
      meta: {
        title: 'Dataunite'
      }
    }, {
      path: '/rdc',
      name: 'Rdc',
      component: () => import('@/views/rdc.vue'),
      meta: {
        title: '实时数据捕获'
      }
    },{
      path: '/lmp',
      name: 'Mlp',
      component: () => import('@/views/lmp.vue'),
      meta: {
        title: '机器学习平台'
      }
    },{
      path: '/dsp',
      name: 'Dsp',
      component: () => import('@/views/dsp.vue'),
      meta: {
        title: '数据服务平台'
      }
    },{
      path: '/dxp',
      name: 'Dxp',
      component: () => import('@/views/dxp.vue'),
      meta: {
        title: '数据交换平台'
      }
    },{
      path: '/dmp',
      name: 'Dmp',
      component: () => import('@/views/dmp.vue'),
      meta: {
        title: '数据脱敏平台'
      }
    },{
      path: '/ipp',
      name: 'Ipp',
      component: () => import('@/views/ipp.vue'),
      meta: {
        title: '指标/变量加工平台'
      }
    },{
      path: '/udp',
      name: 'Udp',
      component: () => import('@/views/udp.vue'),
      meta: {
        title: '联合大数据管理平台'
      }
    },{
      path: '/bap',
      name: 'Bap',
      component: () => import('@/views/bap.vue'),
      meta: {
        title: '大数据分析平台'
      }
    }, {
      path: '/fdo',
      name: 'Fdo',
      component: () => import('@/views/fdo.vue'),
      meta: {
        title: '全栈数据运营平台'
      }
    }, {
      path: '/sp',
      name: 'Sp',
      component: () => import('@/views/sp.vue'),
      meta: {
        title: '解决方案'
      }
    },{
      path: '/cc',
      name: 'Cc',
      component: () => import('@/views/cc.vue'),
      meta: {
        title: '客户案例'
      }
    },{
      path: '/about',
      name: 'About',
      component: () => import('@/views/about.vue'),meta: {
        title: '关于我们'
      }

    }]
  }
]

const router = new VueRouter({
  routes
})

export default router
