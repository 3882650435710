import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/reset.css'
import '@/styles/index.css'
import 'element-ui/lib/theme-chalk/index.css'

import {
  Popover,
	Row,
	Col
} from 'element-ui'

Vue.use(Popover)
Vue.use(Row)
Vue.use(Col)

Vue.config.productionTip = false

router.beforeEach((to, from, next)=>{
  if(to.meta.title) {   //路由发生变化时候修改页面中的title
    document.title = to.meta.title
    document.documentElement.scrollTop = 0
  }
  next()
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
