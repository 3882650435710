<template>
  <div class="app-wrapper">
    <VHeader/>
    <router-view/>
    <VFooter/>
  </div>
</template>
<script>
import VHeader from '@/components/Header'
import VFooter from '@/components/Footer'
export default {
  components: {
    VHeader,
    VFooter
  }
}
</script>
<style lang="scss" scoped>
.app-wrapper {
  padding-top: 72px;
}
</style>
