<template>
  <header>
    <div class="header-wrap">
      <router-link to="/" class="logo">
        <img src="~@/assets/images/logo.png"/>
      </router-link>
      <ul>
        <router-link tag="li" :class="{'active': this.activeUrl=== '/'}" to="/">首页</router-link>
				<el-popover
					placement="bottom"
					trigger="manual"
					v-model="visible"
					>
					<el-row class="product-box" @mouseleave.native="visible = false">
						<el-col :span="8" :class="['product-item', {'active': this.activeUrl=== '/rdc'}]" @click.native="goPage('rdc')">
							<p class="name">实时数据捕获</p>
							<p class="desc">智能实时数据捕获服务平台</p>
						</el-col>
						<el-col :span="8" :class="['product-item', {'active': this.activeUrl=== '/lmp'}]" @click.native="goPage('lmp')">
							<p class="name">机器学习</p>
							<p class="desc">一站式模型开发平台</p>
						</el-col>
						<el-col :span="8" :class="['product-item', {'active': this.activeUrl=== '/dxp'}]" @click.native="goPage('dxp')">
							<p class="name">数据交换平台</p>
							<p class="desc">一站式解决全行级数据交互</p>
						</el-col>
						<el-col  :span="8" :class="['product-item', {'active': this.activeUrl=== '/dmp'}]" @click.native="goPage('dmp')">
							<p class="name">数据脱敏平台</p>
							<p class="desc">解决信息泄露风险</p>
						</el-col>
						<el-col :span="8" :class="['product-item', {'active': this.activeUrl=== '/ipp'}]" @click.native="goPage('ipp')">
							<p class="name">指标加工平台</p>
							<p class="desc">一站式、开箱即用指标加工</p>
						</el-col>
						<el-col :span="8" :class="['product-item', {'active': this.activeUrl=== '/dsp'}]" @click.native="goPage('dsp')">
							<p class="name">数据服务平台</p>
							<p class="desc">发布数据服务、管理数据服务</p>
						</el-col>
						<el-col :span="8" :class="['product-item', {'active': this.activeUrl=== '/bap'}]" @click.native="goPage('bap')">
							<p class="name">数据分析平台</p>
							<p class="desc">一站式体验、开箱即用、集群负载均衡</p>
						</el-col>
						<el-col :span="8" :class="['product-item', {'active': this.activeUrl=== '/udp'}]" @click.native="goPage('udp')">
							<p class="name">大数据平台</p>
							<p class="desc">一站式数据集成、运维</p>
						</el-col>
						<el-col :span="8" :class="['product-item', {'active': this.activeUrl=== '/fdo'}]" @click.native="goPage('fdo')">
							<p class="name">全栈数据运营平台</p>
							<p class="desc">一体化、一站式数据处理运营平台</p>
						</el-col>
					</el-row>
					<li slot="reference" @mouseenter="visible = true" :class="{'active': this.activeUrl=== '/rdc' || this.activeUrl=== '/lmp' || this.activeUrl=== '/dxp' || this.activeUrl=== '/dmp' || this.activeUrl=== '/ipp' || this.activeUrl=== '/dsp' || this.activeUrl=== '/udp'|| this.activeUrl=== '/fdo'|| this.activeUrl=== '/bap'}">产品中心</li>
				</el-popover>
        <!-- <router-link tag="li" :class="{'active': this.activeUrl=== '/mlp'}" to="/mlp">机器学习平台</router-link>
        <router-link tag="li" :class="{'active': this.activeUrl=== '/ds'}" to="/ds">数据服务</router-link>
        <router-link tag="li" :class="{'active': this.activeUrl=== '/sp'}" to="/sp">解决方案</router-link>
        <router-link tag="li" :class="{'active': this.activeUrl=== '/cc'}" to="/cc">客户案例</router-link> -->
        <router-link tag="li" :class="{'active': this.activeUrl=== '/about'}" to="/about">关于我们</router-link>
      </ul>
    </div>
  </header>
</template>
<script>
export default {
	data(){
		return {
			visible: false
		}
	},
  computed: {
    activeUrl(){
      return this.$route.path
    }
  },
	methods: {
		goPage(name){
			this.visible = false
			this.$router.push({
				path: '/' + name
			})
		}
	}
}
</script>
<style lang="scss" scoped>
header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  z-index: 10;
  box-shadow: 0px 1px 8px 0px rgba(6, 0, 1, 0.1);
  .header-wrap{
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    .logo {
      float: left;
      margin-top: 19px;
      margin-right: 116px;
      img {
        width: 160px;
        height: 32px;
      }
    }
    ul {
      float: right;
      line-height: 72px;
      li {
        float: left;
        font-size: 16px;
        font-weight: 500;
        color: #5A5A5A;
        margin-left: 79px;
        cursor: pointer;
        &.active {
          color:#1c2a8a;
          font-weight: bold;
        }
      }
    }
  }
}
.product-box {
	.product-item {
		padding: 20px;
		cursor: pointer;
		&.active {
			.name {
				color: #1c2a8a;
			}
		}
		.name {
			color: #333;
			font-weight: bold;
			margin-bottom: 5px;
		}
	}
}
</style>
